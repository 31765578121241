import React from 'react'
function WebsiteLogo(props) {
    return (
        <svg width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M14.0566 25.5441L13.2818 25.1687L12.5223 25.5759L12.6507 24.7425L12.0195 24.1656L12.8727 24.0257L13.2405 23.2622L13.6387 24.0108L14.4984 24.1147L13.8912 24.717L14.0566 25.5441Z" fill="#357BCE"/>
            <path d="M16.5371 25.5526L15.7623 25.1751L15.0049 25.5844L15.1333 24.7488L14.5 24.172L15.3553 24.0341L15.7231 23.2686L16.1214 24.0193L16.981 24.1232L16.3738 24.7234L16.5371 25.5526Z" fill="#357BCE"/>
            <path d="M19.0175 25.5609L18.2427 25.1834L17.4832 25.5906L17.6138 24.7571L16.9805 24.1803L17.8336 24.0403L18.2014 23.2769L18.6018 24.0255L19.4615 24.1294L18.8521 24.7317L19.0175 25.5609Z" fill="#357BCE"/>
            <path d="M21.498 25.5675L20.721 25.19L19.9637 25.5993L20.0921 24.7638L19.4609 24.187L20.3141 24.0491L20.6819 23.2856L21.0801 24.0343L21.9398 24.1382L21.3326 24.7383L21.498 25.5675Z" fill="#357BCE"/>
            <path d="M23.9765 25.576L23.2017 25.1985L22.4444 25.6057L22.5728 24.7723L21.9395 24.1954L22.7948 24.0555L23.1604 23.292L23.5608 24.0406L24.4205 24.1445L23.8133 24.7468L23.9765 25.576Z" fill="#357BCE"/>
            <path d="M14.5815 29.9933H4.42886C4.2349 29.9936 4.04279 29.9566 3.86352 29.8845C3.68424 29.8123 3.52132 29.7065 3.38407 29.5729C3.24682 29.4394 3.13794 29.2808 3.06365 29.1062C2.98936 28.9316 2.95112 28.7445 2.95112 28.5555V4.31566C2.95112 3.93376 3.10681 3.5675 3.38394 3.29745C3.66107 3.0274 4.03694 2.87569 4.42886 2.87569H14.6577C14.8514 2.87583 15.0433 2.83878 15.2224 2.76665C15.4015 2.69452 15.5642 2.58873 15.7014 2.45531C15.8385 2.32189 15.9473 2.16346 16.0216 1.98907C16.0959 1.81467 16.1342 1.62773 16.1343 1.43891C16.1345 1.25009 16.0964 1.06309 16.0224 0.888586C15.9484 0.714085 15.8398 0.5555 15.7029 0.421885C15.566 0.28827 15.4034 0.182243 15.2244 0.109855C15.0455 0.0374681 14.8536 0.000139244 14.6598 0L1.47556 0C1.08439 0.000560955 0.709414 0.152228 0.432817 0.421755C0.156221 0.691282 0.000575669 1.05668 0 1.43785L0 31.4333C0.000575669 31.8144 0.156221 32.1798 0.432817 32.4494C0.709414 32.7189 1.08439 32.8706 1.47556 32.8711H14.5815C14.9633 32.8568 15.3246 32.699 15.5896 32.4307C15.8545 32.1624 16.0025 31.8046 16.0025 31.4322C16.0025 31.0599 15.8545 30.702 15.5896 30.4337C15.3246 30.1654 14.9633 30.0076 14.5815 29.9933Z" fill="#357BCE"/>
            <path d="M35.5245 0H21.5611C21.1697 0 20.7944 0.151487 20.5177 0.421136C20.241 0.690784 20.0855 1.05651 20.0855 1.43785C20.0855 1.81919 20.241 2.18491 20.5177 2.45456C20.7944 2.72421 21.1697 2.87569 21.5611 2.87569H32.5712C32.7652 2.87569 32.9572 2.91295 33.1364 2.98535C33.3155 3.05774 33.4783 3.16384 33.6153 3.29758C33.7524 3.43132 33.861 3.59008 33.9351 3.76477C34.0091 3.93946 34.047 4.12666 34.0468 4.31566V28.5555C34.047 28.7444 34.0091 28.9315 33.935 29.106C33.861 29.2806 33.7523 29.4392 33.6152 29.5728C33.4781 29.7064 33.3154 29.8123 33.1362 29.8844C32.9571 29.9566 32.765 29.9936 32.5712 29.9933H21.7896C21.4657 29.9936 21.1508 30.0971 20.8929 30.2881L12.6446 36.417C12.3331 36.6483 12.1286 36.9906 12.0759 37.3688C12.0233 37.7469 12.1268 38.13 12.3639 38.4338C12.6015 38.737 12.953 38.9359 13.3411 38.9868C13.7291 39.0377 14.1221 38.9364 14.4336 38.7052L21.8897 33.1659C22.1476 32.9749 22.4625 32.8714 22.7864 32.8711H35.5245C35.9157 32.8706 36.2906 32.7189 36.5672 32.4494C36.8438 32.1798 36.9995 31.8144 37 31.4333V1.43785C36.9995 1.05668 36.8438 0.691282 36.5672 0.421755C36.2906 0.152228 35.9157 0.000560955 35.5245 0V0Z" fill="#357BCE"/>
            <path d="M24.7105 12.6541C24.7105 12.2639 24.5102 11.7507 23.5287 11.5259C22.508 11.2905 21.3524 12.0094 20.7865 10.8175C20.1336 9.4306 21.94 7.6598 20.077 6.64185C19.9513 6.55724 19.8064 6.50376 19.6547 6.48602C19.503 6.46828 19.3492 6.48683 19.2065 6.54006C19.0897 6.61145 18.9931 6.71015 18.9254 6.82719C18.8578 6.94423 18.8212 7.07589 18.8191 7.21021C18.4382 9.90776 17.3305 10.6034 16.782 10.966C15.8571 11.5768 15.1019 12.389 14.1552 12.9616H12.166V18.9653C13.4193 18.9355 14.6663 19.1474 15.8353 19.5888C17.2804 20.0936 20.8409 20.7722 22.1815 19.6715C22.5992 19.3024 22.8697 18.8015 22.9454 18.257C23.1532 18.187 23.3437 18.0754 23.5045 17.9293C23.6653 17.7832 23.7929 17.6059 23.8791 17.4087C24.0048 17.0224 23.9816 16.6047 23.8138 16.2339C23.9998 16.1469 24.1647 16.0226 24.2979 15.869C24.431 15.7155 24.5294 15.5363 24.5864 15.3432C24.7431 14.7345 24.4232 14.2701 24.125 13.7844C24.2956 13.644 24.4353 13.4716 24.5359 13.2774C24.6365 13.0832 24.6959 12.8712 24.7105 12.6541Z" fill="#357BCE"/>
        </svg>


    )
}

export default WebsiteLogo

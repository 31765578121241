import React from "react";

const HalfStar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 39 38"
      fill="none"
    >
      <path
        d="M34.4833 11.1945L24.2959 10.2497L20.2492 0.852857C19.9658 0.194881 19.0329 0.194881 18.7496 0.852857L14.7029 10.2496L4.51553 11.1945C3.80217 11.2607 3.51394 12.1479 4.05209 12.6207L11.7385 19.3731L9.48905 29.3539C9.33153 30.0528 10.0863 30.601 10.7022 30.2353L19.4994 25.0117L28.2965 30.2353C28.9126 30.6011 29.6672 30.0528 29.5097 29.3539L27.2602 19.3731L34.9467 12.6207C35.4849 12.1479 35.1965 11.2607 34.4833 11.1945Z"
        fill="#FFDC64"
      />
      <path
        d="M9.08639 0.529523C9.60573 1.25087 11.0716 4.3488 11.9096 6.15424C12.0414 6.43806 11.6853 6.69445 11.4579 6.47942C10.0117 5.11197 7.53873 2.73905 7.01939 2.01763C6.60844 1.44687 6.73801 0.651018 7.30884 0.240068C7.8796 -0.170882 8.67544 -0.0412365 9.08639 0.529523Z"
        fill="#FFF082"
      />
      <path
        d="M29.9142 0.529523C29.3949 1.25087 27.929 4.3488 27.0909 6.15424C26.9592 6.43806 27.3153 6.69445 27.5427 6.47942C28.9889 5.11197 31.4618 2.73898 31.9812 2.01763C32.3922 1.44687 32.2626 0.651018 31.6918 0.240068C31.121 -0.170882 30.3251 -0.0412365 29.9142 0.529523Z"
        fill="#FFF082"
      />
      <path
        d="M38.1208 21.683C37.2757 21.4074 33.8788 20.9522 31.9042 20.7023C31.5938 20.663 31.4577 21.0802 31.7316 21.2315C33.4738 22.1939 36.4859 23.8289 37.331 24.1045C37.9996 24.3226 38.7185 23.9573 38.9366 23.2886C39.1546 22.6199 38.7894 21.9011 38.1208 21.683Z"
        fill="#FFF082"
      />
      <path
        d="M0.878917 21.683C1.72397 21.4074 5.12087 20.9522 7.09548 20.7023C7.40589 20.663 7.54193 21.0802 7.26809 21.2315C5.5258 22.1939 2.51372 23.8289 1.66867 24.1045C1.00003 24.3226 0.281193 23.9573 0.0631122 23.2886C-0.154969 22.6199 0.210277 21.9011 0.878917 21.683Z"
        fill="#FFF082"
      />
      <path
        d="M18.2656 36.0549C18.2656 35.1661 18.886 31.7955 19.2608 29.8407C19.3197 29.5334 19.7585 29.5334 19.8174 29.8407C20.1922 31.7955 20.8126 35.1661 20.8126 36.0549C20.8126 36.7582 20.2425 37.3284 19.5392 37.3284C18.8358 37.3285 18.2656 36.7582 18.2656 36.0549Z"
        fill="#FFF082"
      />
      <path
        d="M21.7206 4.26995L20.2491 0.852857C19.9657 0.194881 19.0328 0.194881 18.7495 0.852857L14.7028 10.2496L4.51553 11.1945C3.80217 11.2607 3.51394 12.1479 4.05209 12.6207L11.7385 19.3731L9.48904 29.3539C9.33152 30.0528 10.0863 30.601 10.7022 30.2353L11.8731 29.5401C13.7925 17.1149 19.1887 7.99386 21.7206 4.26995Z"
        fill="#FFC850"
      />
      <mask
        id="mask0_937_497"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="39"
        height="38"
      >
        <path
          d="M34.4833 11.1945L24.2959 10.2497L20.2492 0.852857C19.9658 0.194881 19.0329 0.194881 18.7496 0.852857L14.7029 10.2496L4.51553 11.1945C3.80217 11.2607 3.51394 12.1479 4.05209 12.6207L11.7385 19.3731L9.48905 29.3539C9.33153 30.0528 10.0863 30.601 10.7022 30.2353L19.4994 25.0117L28.2965 30.2353C28.9126 30.6011 29.6672 30.0528 29.5097 29.3539L27.2602 19.3731L34.9467 12.6207C35.4849 12.1479 35.1965 11.2607 34.4833 11.1945Z"
          fill="#FFDC64"
        />
        <path
          d="M9.08639 0.529523C9.60573 1.25087 11.0716 4.3488 11.9096 6.15424C12.0414 6.43806 11.6853 6.69445 11.4579 6.47942C10.0117 5.11197 7.53873 2.73905 7.01939 2.01763C6.60844 1.44687 6.73801 0.651018 7.30884 0.240068C7.8796 -0.170882 8.67544 -0.0412365 9.08639 0.529523Z"
          fill="#FFF082"
        />
        <path
          d="M29.9142 0.529523C29.3949 1.25087 27.929 4.3488 27.0909 6.15424C26.9592 6.43806 27.3153 6.69445 27.5427 6.47942C28.9889 5.11197 31.4618 2.73898 31.9812 2.01763C32.3922 1.44687 32.2626 0.651018 31.6918 0.240068C31.121 -0.170882 30.3251 -0.0412365 29.9142 0.529523Z"
          fill="#FFF082"
        />
        <path
          d="M38.1208 21.683C37.2757 21.4074 33.8788 20.9522 31.9042 20.7023C31.5938 20.663 31.4577 21.0802 31.7316 21.2315C33.4738 22.1939 36.4859 23.8289 37.331 24.1045C37.9996 24.3226 38.7185 23.9573 38.9366 23.2886C39.1546 22.6199 38.7894 21.9011 38.1208 21.683Z"
          fill="#FFF082"
        />
        <path
          d="M0.878917 21.683C1.72397 21.4074 5.12087 20.9522 7.09548 20.7023C7.40589 20.663 7.54193 21.0802 7.26809 21.2315C5.5258 22.1939 2.51372 23.8289 1.66867 24.1045C1.00003 24.3226 0.281193 23.9573 0.0631122 23.2886C-0.154969 22.6199 0.210277 21.9011 0.878917 21.683Z"
          fill="#FFF082"
        />
        <path
          d="M18.2656 36.0549C18.2656 35.1661 18.886 31.7955 19.2608 29.8407C19.3197 29.5334 19.7585 29.5334 19.8174 29.8407C20.1922 31.7955 20.8126 35.1661 20.8126 36.0549C20.8126 36.7582 20.2425 37.3284 19.5392 37.3284C18.8358 37.3285 18.2656 36.7582 18.2656 36.0549Z"
          fill="#FFF082"
        />
        <path
          d="M21.7206 4.26995L20.2491 0.852857C19.9657 0.194881 19.0328 0.194881 18.7495 0.852857L14.7028 10.2496L4.51553 11.1945C3.80217 11.2607 3.51394 12.1479 4.05209 12.6207L11.7385 19.3731L9.48904 29.3539C9.33152 30.0528 10.0863 30.601 10.7022 30.2353L11.8731 29.5401C13.7925 17.1149 19.1887 7.99386 21.7206 4.26995Z"
          fill="#FFC850"
        />
      </mask>
      <g mask="url(#mask0_937_497)">
        <path d="M19 -5H45V42L15 39L19 30.25V18.5V-5Z" fill="#E8E8E8" />
      </g>
    </svg>
  );
};

export default HalfStar;

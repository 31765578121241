import { useEffect, useState } from "react";
import "./sass/main.scss";
import { Helmet } from "react-helmet";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { axios } from "./axios/axiosInstance";
//Components
import { clientActions } from "./Redux/clientslice/clientSlice";
import { statusAction } from "./Redux/statusSlice";
import { brandAction } from "./Redux/brandInfoSlice/brandInfoSlice";
//Pages
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Admin from "./page/admin/Admin";
import HomePage from "./page/Homepage/HomePage";
import BrandPanel from "./page/BrandPanel/BrandPanel";
import ComparisonPage from "./page/Comparison/ComparisonPage";
import SignUp from "./page/Sign_up/SignUp";
import Login from "./page/login/Login";
import BrandSignUp from "./page/brand_sign_up/BrandSignUp";
import BrandLogin from "./page/brand_log_in/BrandLogin";
import SearchBrand from "./page/search/SearchBrand";
import PhoneVerification from "./page/phone_verification_page/PhoneVerification";
import Error404Page from "./page/error_404_page/Error404Page";
import TermsAndCondition from "./page/terms_and_condition/TermsAndCondition";
import WriteReview from "./page/write_review/WriteReview";
import EmailVerificationPage from "./page/email_verification_page/EmailVerificationPage";
import ForgotPasswordPage from "./page/forgotPasswordPage/ForgotPasswordPage";
import Profile from "./page/profile/Profile";

import Notification from "./components/notification/Notification";

const baseUrl = "https://sikayetbox.com";

function App() {
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.client);
  const { attemptingLoginOnSiteLoad } = useSelector((state) => state.status);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [isState, setIsState] = useState(false);
  useEffect(() => {
    axios.get("brand/getAllBrands").then(({ data }) => {
      dispatch(brandAction.setBrands(data));
    });
  }, []);

  useEffect(() => {
    if (
      attemptingLoginOnSiteLoad === null ||
      attemptingLoginOnSiteLoad === undefined
    ) {
      setIsState(true);
    }
  }, [attemptingLoginOnSiteLoad]);

  useEffect(() => {
    const remember = !!localStorage.getItem("remember");
    let type = localStorage.getItem("clientType");

    const maintainSession = !!sessionStorage.getItem("maintainSession");
    let currentTabCount = localStorage.getItem("tabCount");
    console.log(currentTabCount);
    if (!maintainSession && !currentTabCount && !remember) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("clientType");
      localStorage.removeItem("brand  Id");
      localStorage.removeItem("userId");
    }
    if (currentTabCount) {
      if (!maintainSession) {
        localStorage.setItem("tabCount", parseInt(currentTabCount) + 1);
      }
    }
    if (type === "user") {
      let userId = localStorage.getItem("userId");
      let accessToken = localStorage.getItem("accessToken");
      if (userId && accessToken) {
        dispatch(statusAction.setAttemptingLogin(true));
        axios
          .get(`/auth/user/login/${userId}`, {
            headers: {
              authorization: `bearer ${accessToken}`,
            },
          })
          .then(({ data: user }) => {
            dispatch(clientActions.setClient(user));
            dispatch(statusAction.setAttemptingLogin(false));
          })
          .catch((err) => {
            localStorage.removeItem("userId");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("clientType");
          });
      }
    } else if (type === "brand") {
      let brandId = localStorage.getItem("brandId");
      let accessToken = localStorage.getItem("accessToken");

      if (brandId && accessToken) {
        dispatch(statusAction.setAttemptingLogin(true));
        axios
          .get(`/auth/brand/login/${brandId}`, {
            headers: {
              authorization: `bearer ${accessToken}`,
            },
          })
          .then(({ data: brand }) => {
            dispatch(clientActions.setClient(brand));
            dispatch(statusAction.setAttemptingLogin(false));
          })
          .catch((err) => {
            localStorage.removeItem("brandId");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("clientType");
          });
      }
    }
    setLoadingStatus(true);
    window.onbeforeunload = () => {
      let tabCount = localStorage.getItem("tabCount");
      console.log(tabCount);
      if (tabCount) {
        tabCount = parseInt(tabCount);
        if (tabCount >= 1 && !maintainSession) {
          localStorage.setItem("tabCount", tabCount - 1);
        } else {
          localStorage.removeItem("tabCount");
        }
      }
    };
  }, [dispatch]);

  const handleRedirect = (message, type = "error") => {
    if (!message) return <Redirect to="/" />;
    console.log(message);
    dispatch(
      statusAction.setNotification({
        message,
        type,
      })
    );
    return <Redirect to="/" />;
  };
  console.log("rendered");
  return (
    <div className="app">
      {/* {isState
        ? !attemptingLoginOnSiteLoad
          ? // <Background />
            null
          : null
        : null} */}

      <Router>
        <Notification />
        <Header />
        {isState ? (
          !attemptingLoginOnSiteLoad ? (
            <Switch>
              <Route path="/" exact>
                <Helmet>
                  <title>ŞikayetBox -Tüm Markaların Yorum ve Şikayetleri</title>
                  <meta
                    name="ŞikayetBox"
                    content="Tüm Markaların Yorum ve Şikayetleri"
                  />
                  <link href={`${baseUrl}`} rel="canonical" />
                </Helmet>
                <HomePage />
              </Route>

              <Route exact path="/user/login">
                <Helmet>
                  <title>Giriş - ŞikayetBox</title>
                  <meta name="Giriş Yap" content="ŞikayetBox - Giriş Yap" />
                  <link href={`${baseUrl}/user/login`} rel="canonical" />
                </Helmet>
                {!client ? <Login /> : <Redirect to="/" exact />}
              </Route>

              <Route exact path="/user/signup">
                <Helmet>
                  <title>Üye Ol - ŞikayetBox</title>
                  <meta name="Üye Ol" content="ŞikayetBox - Üye Ol" />
                  <link href={`${baseUrl}/user/signup`} rel="canonical" />
                </Helmet>
                {!client ? <SignUp /> : <Redirect to="/" exact />}
              </Route>

              <Route exact path="/user/:userId">
                <Profile />
              </Route>

              <Route path="/review/:brandId?">
                <Helmet>
                  <title>Yorum Yaz - ŞikayetBox</title>
                  <meta
                    name="Yorum Yaz"
                    content="Şikayet ve Yorum Yaz - ŞikayetBox"
                  />
                  <link href={`${baseUrl}/review`} rel="canonical" />
                </Helmet>
                <WriteReview />
              </Route>
              <Route exact path="/brand/login">
                <Helmet>
                  <title>Giriş Yap - ŞikayetBox</title>
                  <meta
                    name="Marka Girişi"
                    content="Marka Girişi - ŞikayetBox"
                  />
                  <link href={`${baseUrl}/brand/login`} rel="canonical" />
                </Helmet>
                {!client ? <BrandLogin /> : () => handleRedirect()}
              </Route>
              {/* <Route exact path="/brand/signup" > 
                    {
                      !client 
                      ? 
                        <BrandSignUp />
                      :
                        <Redirect to='/' exact />
                    }
              </Route> */}

              <Route exact path="/brand/panel/:brandId">
                <Helmet>
                  <title>Marka Paneli - ŞikayetBox</title>
                  <meta name="Brand Panel" content="Brand panel" />
                  <link href={`${baseUrl}/brand/panel`} rel="canonical" />
                </Helmet>
                {client ? (
                  (client.type.includes("brand") ||
                    client.type.includes("admin")) && <BrandPanel />
                ) : (
                  <Redirect to="/" exact />
                )}
              </Route>

              <Route path="/brand/comparison/:brandSlug1/:brandSlug2">
                <Helmet>
                  <title>Karşılaştırma - ŞikayetBox</title>
                  <meta
                    name="Compare two brands"
                    content="Compare two brands to make a better choice"
                  />
                  <link href={`${baseUrl}/brand/comparison`} rel="canonical" />
                </Helmet>
                <ComparisonPage />
              </Route>
              <Route path="/brand/:brandSlug" component={SearchBrand} />

              <Route exact path="/admin">
                {client ? (
                  client.type.includes("admin") && <Admin />
                ) : (
                  <Redirect to="/" exact />
                )}
              </Route>
              <Route exact path="/admin/login">
                {!client ? <Admin /> : <Redirect to="/" exact />}
              </Route>
              <Route path="/phoneverification">
                <PhoneVerification />
              </Route>
              <Route path="/verify-email/:token/:type">
                <EmailVerificationPage />
              </Route>
              <Route path="/termsandcondition">
                <Helmet>
                  <title>Kural ve Şartlar - ŞikayetBox</title>
                  <meta
                    name="Kural ve Şartlar"
                    content="ŞikayetBox - Kural ve Şartlar"
                  />
                  <link href={`${baseUrl}/termsandcondition`} rel="canonical" />
                </Helmet>
                <TermsAndCondition />
              </Route>
              <Route path="/forgot-password/:token/:type">
                <ForgotPasswordPage />
              </Route>
              <Route path="/*">
                <Error404Page />
              </Route>
            </Switch>
          ) : null
        ) : null}
        <Footer />
      </Router>
    </div>
  );
}

export default App;

import React from "react";
import { Link } from "react-router-dom";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillInstagram,
} from "react-icons/ai";
import WebisteLogo from "../../assests/WebsiteLogo";
const Footer = () => {
  return (
    <section className="footer">
      <div className="footer__content">
        <div className="footer__content__intro">
          <div className="footer__content__intro__flex">
            <WebisteLogo />
            <Link to="/" className="footer__rights__link">
              <h4>ŞikayetBox</h4>
            </Link>
          </div>

          <p>
            Şikayetbox 2005 yılında kurulmuştur. Şikayetbox siz değerli
            kullanıcıları doğrudan marka temsilcileri ile buluşturan, güvenilir
            bir internet ortamı yaratarak iki tarafın da haklarına koruyan bir
            platformdur.
          </p>
        </div>
        <div className="footer__content__links">
          <Link
            to="/termsandcondition"
            className="footer__content__links__title"
          >
            <h4>Kural ve Şartlar</h4>
          </Link>
          <div className="footer__content__social__links">
            {/* <div className="footer__content__social__links-facebook">
              <a href = 'https://www.facebook.com/' target="_blank" rel="noopener noreferrer"><AiFillFacebook className="footer__content__social__links-facebook__icon"/></a>
            </div> */}

            <div className="footer__content__social__links-twitter">
              <a
                href="https://twitter.com/SikayetBox"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillTwitterSquare className="footer__content__social__links-twitter__icon" />
              </a>
            </div>

            <div className="footer__content__social__links-instagram">
              <a
                href="https://www.instagram.com/sikayetbox/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillInstagram className="footer__content__social__links-instagram__icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__rights">
        <Link to="/" className="footer__rights__link">
          <h4>ŞikayetBox</h4>
        </Link>
        <p>© 2022 ŞikayetBox, Tüm hakları saklıdır </p>
        <p>Doğru markayı bulmanın adresine hoşgeldiniz.</p>
      </div>
    </section>
  );
};

export default Footer;

import React, { useEffect, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { statusAction } from "../../Redux/statusSlice";
import { GrFormClose } from "react-icons/gr";

function Notification() {
  const { notification } = useSelector((state) => state.status);
  const firstRender = useRef(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (firstRender.current) {
      setTimeout(() => {
        dispatch(statusAction.removeNotification());
      }, 5000);
    }
    firstRender.current = true;
  }, [notification, dispatch]);

  const closeNotification = () => {
    dispatch(statusAction.removeNotification());
  };

  return !!notification ? (
    notification.show ? (
      <div
        className="notification-container"
        style={{ backgroundColor: notification.color }}
      >
        <div className="notification">
          <p className="notification__text">{notification.message}</p>
          <div onClick={closeNotification} className="notification__close">
            <GrFormClose className="notification__close__icon" />
          </div>
        </div>
      </div>
    ) : null
  ) : null;
}

export default memo(Notification);

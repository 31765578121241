import React from "react";

function StarTableHomePage({
  starGradient1,
  starGradient2,
  starLines,
  ...rest
}) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.6841 5.74105L12.4598 5.25652L10.3846 0.437637C10.2393 0.100215 9.76088 0.100215 9.61561 0.437637L7.54037 5.25648L2.3161 5.74105C1.95028 5.77495 1.80247 6.22995 2.07845 6.47242L6.02017 9.93516L4.86662 15.0535C4.78583 15.4119 5.17291 15.6931 5.48877 15.5055L10.0001 12.8268L14.5114 15.5055C14.8273 15.6931 15.2143 15.4119 15.1336 15.0535L13.98 9.93516L17.9218 6.47242C18.1977 6.22995 18.0499 5.77495 17.6841 5.74105Z"
        fill={starGradient1}
      />
      <path
        d="M4.65969 0.271549C4.92602 0.641472 5.67774 2.23015 6.1075 3.15601C6.17508 3.30156 5.99246 3.43304 5.87586 3.32277C5.13418 2.62151 3.86601 1.40464 3.59968 1.03468C3.38894 0.74198 3.45539 0.333854 3.74812 0.123111C4.04082 -0.0876313 4.44894 -0.0211468 4.65969 0.271549Z"
        fill={starLines}
      />
      <path
        d="M15.3413 0.271549C15.075 0.641472 14.3233 2.23015 13.8935 3.15601C13.8259 3.30156 14.0085 3.43304 14.1251 3.32277C14.8668 2.62151 16.1349 1.4046 16.4013 1.03468C16.6121 0.74198 16.5456 0.333854 16.2529 0.123111C15.9602 -0.0876313 15.5521 -0.0211468 15.3413 0.271549Z"
        fill={starLines}
      />
      <path
        d="M19.5494 11.1195C19.1161 10.9782 17.3741 10.7447 16.3615 10.6166C16.2023 10.5964 16.1325 10.8104 16.2729 10.8879C17.1664 11.3815 18.7111 12.2199 19.1444 12.3612C19.4873 12.4731 19.856 12.2858 19.9678 11.9429C20.0796 11.5999 19.8923 11.2313 19.5494 11.1195Z"
        fill={starLines}
      />
      <path
        d="M0.450725 11.1195C0.884086 10.9782 2.62608 10.7447 3.6387 10.6166C3.79788 10.5964 3.86765 10.8104 3.72722 10.8879C2.83374 11.3815 1.28909 12.2199 0.855726 12.3612C0.512835 12.4731 0.144201 12.2858 0.0323652 11.9429C-0.079471 11.5999 0.107834 11.2313 0.450725 11.1195Z"
        fill={starLines}
      />
      <path
        d="M9.36719 18.49C9.36719 18.0342 9.68535 16.3057 9.87754 15.3032C9.90774 15.1457 10.1328 15.1457 10.163 15.3032C10.3552 16.3057 10.6733 18.0342 10.6733 18.49C10.6733 18.8507 10.3809 19.1431 10.0203 19.1431C9.65957 19.1431 9.36719 18.8507 9.36719 18.49Z"
        fill={starLines}
      />
      <path
        d="M11.1392 2.18998L10.3845 0.437637C10.2392 0.100215 9.76083 0.100215 9.61556 0.437637L7.54032 5.25647L2.3161 5.74105C1.95028 5.77495 1.80247 6.22995 2.07845 6.47241L6.02017 9.93516L4.86661 15.0535C4.78583 15.4119 5.1729 15.6931 5.48876 15.5055L6.08919 15.149C7.07353 8.77711 9.84079 4.09968 11.1392 2.18998Z"
        fill={starGradient2}
      />
    </svg>
  );
}

export default StarTableHomePage;

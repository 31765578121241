import React from 'react'

function SearchIcon(props) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} >
            <path d="M17.6278 13.85C20.7919 10.6858 20.7919 5.53729 17.6278 2.37312C14.4637 -0.791041 9.31521 -0.791041 6.15112 2.37312C3.46675 5.05749 3.06021 9.04982 4.93038 12.1674C4.93038 12.1674 5.06472 12.3928 4.88328 12.5741C3.84824 13.6091 0.742895 16.7145 0.742895 16.7145C-0.0812143 17.5385 -0.2774 18.6908 0.453416 19.4218L0.579246 19.5475C1.31006 20.2784 2.46244 20.0823 3.28648 19.2582C3.28648 19.2582 6.38525 16.1593 7.41817 15.1265C7.6083 14.9364 7.83361 15.0707 7.83361 15.0707C10.9511 16.9408 14.9435 16.5343 17.6278 13.85ZM7.64934 12.3517C5.31135 10.0137 5.31141 6.20954 7.64941 3.87154C9.9874 1.53362 13.7915 1.53355 16.1295 3.87154C18.4675 6.20947 18.4675 10.0137 16.1295 12.3517C13.7915 14.6895 9.9874 14.6895 7.64934 12.3517Z" fill="black" fillOpacity="0.5"/>
            <path d="M8.16655 7.63019C8.05773 7.63019 7.9471 7.6088 7.84047 7.56376C7.41454 7.38349 7.21526 6.89203 7.39553 6.46602C8.52857 3.78843 11.6287 2.53187 14.3062 3.66492C14.7322 3.84519 14.9314 4.33665 14.7512 4.76265C14.5708 5.18866 14.0795 5.38781 13.6534 5.2076C11.8266 4.43458 9.71123 5.29194 8.93828 7.11875C8.80304 7.43826 8.49288 7.63019 8.16655 7.63019Z" fill="black" fillOpacity="0.5"/>
        </svg>

    )
}

export default SearchIcon

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import StarTableHomePage from "../../../assests/StarTableHomePage";
import { getAverageReviewRating } from "../../../helpers/getAverageReview";
import Search from "./Search";
import { useDispatch } from "react-redux";
import { statusAction } from "../../../Redux/statusSlice";

function Header({ brands }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { client } = useSelector((state) => state.client);
  const handleWriteReview = () => {
    if (!!client) {
      if (client.type.includes("user")) {
        history.push("/review");
        return;
      } else {
        dispatch(
          statusAction.setNotification({
            message: "Sadece kullanıcılar yorum yapabilir",
            type: "error",
          })
        );
      }
    } else {
      dispatch(
        statusAction.setNotification({
          message: "Yorum yapabilmek için giriş yapmalısınız",
          type: "error",
        })
      );
    }
  };

  return (
    <div className="homepage__header-container">
      <div className="homepage__header">
        <div className="homepage__header__left">
          <div className="homepage__header__left__sub">
            <h1 className="homepage__header__infoHeading">
              En İyi Marka ve Ürününü Bizimle Seçin
            </h1>
            <p className="homepage__header__infoText">
              Markalar, şirketler veya internet siteleri hakkında ki
              yorumlarınızı bizlerle paylaşarak siz de diğer kullanıcılara
              yardımda bulunabilir veya yazılmış olan yorumlarla kararınızı
              rahatlıkla verebilirsiniz.
            </p>
            <button
              onClick={handleWriteReview}
              className="homepage__header__link"
            >
              Yorum Yaz
            </button>
          </div>
        </div>
        <div className="homepage__header__search">
          <Search />
        </div>
        <div className="homepage__header__right">
          <div className="homepage__header__brand">
            <div className="homepage__header__brand__col1">
              <div className="homepage__header__brand__item">
                <Link
                  to={`/brand/${brands[0].slug}`}
                  className="homepage__header__brand__item__logo-container"
                >
                  <img
                    src={brands[0].logo}
                    className="homepage__header__brand__item__logo"
                  />
                </Link>
                <div className="homepage__header__brand__item__rating">
                  <StarTableHomePage
                    starGradient1="#FFDC64"
                    starGradiet2="#FFC850"
                    starLines="#FFF082"
                  />
                  <p>
                    {brands[0].averageRating.toFixed(1)} Puan{" "}
                    {brands[0].reviews.length} Yorumdan
                  </p>
                </div>
              </div>
              <div className="homepage__header__brand__item">
                <Link
                  to={`/brand/${brands[1].slug}`}
                  className="homepage__header__brand__item__logo-container"
                >
                  <img
                    src={brands[1].logo}
                    className="homepage__header__brand__item__logo"
                  />
                </Link>
                <div className="homepage__header__brand__item__rating">
                  <StarTableHomePage
                    starGradient1="#FFDC64"
                    starGradiet2="#FFC850"
                    starLines="#FFF082"
                  />
                  <p>
                    {brands[1].averageRating.toFixed(1)} Puan{" "}
                    {brands[1].reviews.length} Yorumdan
                  </p>
                </div>
              </div>
            </div>
            <div className="homepage__header__brand__col2">
              <div className="homepage__header__brand__item">
                <Link
                  to={`/brand/${brands[2].slug}`}
                  className="homepage__header__brand__item__logo-container"
                >
                  <img
                    src={brands[2].logo}
                    className="homepage__header__brand__item__logo"
                  />
                </Link>
                <div className="homepage__header__brand__item__rating">
                  <StarTableHomePage
                    starGradient1="#FFDC64"
                    starGradiet2="#FFC850"
                    starLines="#FFF082"
                  />
                  <p>
                    {brands[2].averageRating.toFixed(1)} Puan{" "}
                    {brands[2].reviews.length} Yorumdan
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
